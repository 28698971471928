// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-auth-0-callback-js": () => import("./../src/pages/auth0_callback.js" /* webpackChunkName: "component---src-pages-auth-0-callback-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-palette-account-js": () => import("./../src/pages/palette/account.js" /* webpackChunkName: "component---src-pages-palette-account-js" */),
  "component---src-pages-palette-bookmarks-js": () => import("./../src/pages/palette/bookmarks.js" /* webpackChunkName: "component---src-pages-palette-bookmarks-js" */),
  "component---src-pages-palette-hearts-js": () => import("./../src/pages/palette/hearts.js" /* webpackChunkName: "component---src-pages-palette-hearts-js" */),
  "component---src-pages-palette-recipes-js": () => import("./../src/pages/palette/recipes.js" /* webpackChunkName: "component---src-pages-palette-recipes-js" */),
  "component---src-pages-recipe-js": () => import("./../src/pages/recipe.js" /* webpackChunkName: "component---src-pages-recipe-js" */),
  "component---src-pages-search-js": () => import("./../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

